<template>
  <div class="words">
    <a-row class="tabBar block">
      <a-col :span="6" class="tabBarItem">
        <div class="name">总数抽查</div>
        <div class="num">{{statis.word_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">剩余刷题</div>
        <div class="num">{{statis.word_count-statis.master_count-statis.sweep_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">掌握复习</div>
        <div class="num">{{statis.master_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">生疏扫荡</div>
        <div class="num">{{statis.sweep_count}}</div>
      </a-col>
    </a-row>
    <div class="block tableBox">
      <div class="boxSearch">
        <div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="display: flex;align-items: center;justify-content: space-between;margin-right: 10px;">
            <div>
              <a-input-search placeholder="请输入试卷名称" style="width:320px;" v-model="param.name" @input="search" />
            </div>
          </div>
        </div>
      </div>
      <a-list class="listWrap" item-layout="horizontal" :data-source="list" :pagination="{total:total,onChange:changeTable,}" :loading="isLoading">
        <a-list-item class="listItem" slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="'更新时间：'+item.update_date">
            <a slot="title" style="cursor:pointer;color:#1890ff" @click="$router.push({path:'/articlePaper/articleList',query:{id:item.id}})">{{item.name}}</a>
          </a-list-item-meta>
          <div class="gtc gw-200p">
            <div>考试项目</div>
            <div>{{subjectList[item.subject_type-1]}}</div>
          </div>
          <div class="gtc">
            <div>篇数</div>
            <div>{{item.important_word_count}}</div>
          </div>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script>
import { getPredictPage, getStatis, reset } from '@/api/articlePaper'
import { subjectList } from '@/utils/common'
export default {
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)),
      param: {
        name: '',
        page: 1,
        limit: 10,
      },
      sortList: [],
      list: [],
      total: 0,
      statis: {
        word_count: 0,
        master_count: 0,
        sweep_count: 0,
      },
      isLoading: false,
    }
  },
  created() {
    //统计
    this.getStatis()
    this.search()
  },
  methods: {
    getStatis() {
      //统计
      getStatis().then((res) => {
        if (res.code == 200) {
          this.statis = res.data
        }
      })
    },
    changeTable(page) {
      //分页
      this.param.page = page
      this.search()
    },
    toStudy(item) {
      //听写练习
      let num = 0
      switch (item.type) {
        case 1:
          num = item.important_word_count
          break
        case 2:
          num = item.important_word_count - item.master_count - item.sweep_count
          break
        case 3:
          num = item.master_count
          break
        case 4:
          num = item.sweep_count
          break
      }
      if (!num) {
        this.$message.success('恭喜，您已完成此模块学习！')
        return
      }
      this.$router.push({ path: '/importantLearn', query: { id: item.id, type: item.type, name: item.name } })
    },
    reset(id) {
      //重置

      let _this = this
      this.$confirm({
        title: '提示',
        content: '是否重置该项',
        onOk() {
          reset(id).then((data) => {
            if (data.code === 200) {
              _this.$message.success('重置成功')
              _this.getStatis()
              _this.search()
            }
          })
        },
      })
    },
    search() {
      this.isLoading = true
      getPredictPage(this.param).then((data) => {
        this.isLoading = false
        if (data.code === 200) {
          this.list = data.data.map((item) => {
            return {
              ...item,
              type: 1,
            }
          })
          this.total = data.total
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.block {
  background: #fff;
  margin-bottom: 10px;
  padding: 24px;
}
.words {
  .tabBar {
    .tabBarItem {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid #999;
      &:last-child {
        border: 0;
      }
      .name {
        color: #999;
      }
      .num {
        color: rgb(24, 144, 255);
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  .tableBox {
    .boxSearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .listWrap {
      margin-top: 10px;
      .listItem {
        .numItem {
          width: 100px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.active,
          &:hover {
            color: rgb(24, 144, 255);
            border-right: 1px solid rgb(24, 144, 255);
            border-left: 1px solid rgb(24, 144, 255);
          }
        }
        .option {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>